@font-face {
	font-family: 'AdobeCaslonPro';
	src: local('Adobse Caslon Pro'), local('AdobeCaslonPro'),
		url('./fonts/ACaslonPro-Regular.otf') format('opentype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'AdobeCaslonPro';
	font-weight: 600;
	font-display: swap;
	src: local('Adobse Caslon Pro'), local('AdobeCaslonPro'),
		url('./fonts/ACaslonPro-Semibold.otf') format('opentype');
}

@font-face {
	font-family: 'AdobeCaslonPro';
	font-weight: 600;
	font-style: italic;
	font-display: swap;
	src: local('Adobse Caslon Pro'), local('AdobeCaslonPro'),
		url('./fonts/ACaslonPro-SemiboldItalic.otf') format('opentype');
}

@font-face {
	font-family: 'AdobeCaslonPro';
	font-weight: 700;
	font-display: swap;
	src: local('Adobse Caslon Pro'), local('AdobeCaslonPro'),
		url('./fonts/ACaslonPro-Bold.otf') format('opentype');
}

@font-face {
	font-family: 'AdobeCaslonPro';
	font-weight: normal;
	font-style: italic;
	font-display: swap;
	src: local('Adobse Caslon Pro'), local('AdobeCaslonPro'),
		url('./fonts/ACaslonPro-Italic.otf') format('opentype');
}
@font-face {
	font-family: 'AdobeCaslonPro';
	font-weight: 700;
	font-style: italic;
	font-display: swap;
	src: local('Adobse Caslon Pro'), local('AdobeCaslonPro'),
		url('./fonts/ACaslonPro-BoldItalic.otf') format('opentype');
}

@font-face {
	font-family: 'AkzidenzGroteskPro';
	src: local('Akzidenz Grotesk Pro'), local('AkzidenzGroteskPro'),
		url('./fonts/akzidenzgroteskpro-light-webfont.woff2') format('woff2'),
		url('./fonts/akzidenzgroteskpro-light-webfont.woff') format('woff'),
		url('./fonts/akzidenzgroteskpro-light-webfont.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'AkzidenzGroteskPro';
	src: local('Akzidenz Grotesk Pro'), local('AkzidenzGroteskPro'),
		url('./fonts/akzidenzgroteskpro-regular-webfont.woff2') format('woff2'),
		url('./fonts/akzidenzgroteskpro-regular-webfont.woff') format('woff'),
		url('./fonts/akzidenzgroteskpro-regular-webfont.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'AkzidenzGroteskPro';
	src: local('Akzidenz Grotesk Pro'), local('AkzidenzGroteskPro'),
		url('./fonts/akzidenzgroteskpro-md-webfont.woff2') format('woff2'),
		url('./fonts/akzidenzgroteskpro-md-webfont.woff') format('woff'),
		url('./fonts/akzidenzgroteskpro-md-webfont.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'AkzidenzGroteskPro';
	src: local('Akzidenz Grotesk Pro'), local('AkzidenzGroteskPro'),
		url('./fonts/akzidenzgroteskpro-bold-webfont.woff2') format('woff2'),
		url('./fonts/akzidenzgroteskpro-bold-webfont.woff') format('woff'),
		url('./fonts/akzidenzgroteskpro-bold-webfont.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'AkzidenzGroteskPro';
	src: local('Akzidenz Grotesk Pro'), local('AkzidenzGroteskPro'),
		url('./fonts/akzidenzgroteskpro-it-webfont.woff2') format('woff2'),
		url('./fonts/akzidenzgroteskpro-it-webfont.woff') format('woff'),
		url('./fonts/akzidenzgroteskpro-it-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

/* roboto-100 - latin */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-display: swap;
	font-weight: 100;
	src: local(''), url('./fonts/roboto-v29-latin-100.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url('./fonts/roboto-v29-latin-100.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-300 - latin */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-display: swap;
	font-weight: 300;
	src: local(''), url('./fonts/roboto-v29-latin-300.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url('./fonts/roboto-v29-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-regular - latin */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-display: swap;
	font-weight: 400;
	src: local(''), url('./fonts/roboto-v29-latin-regular.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url('./fonts/roboto-v29-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-500 - latin */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-display: swap;
	font-weight: 500;
	src: local(''), url('./fonts/roboto-v29-latin-500.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url('./fonts/roboto-v29-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-700 - latin */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-display: swap;
	font-weight: 700;
	src: local(''), url('./fonts/roboto-v29-latin-700.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url('./fonts/roboto-v29-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
